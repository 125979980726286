import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../../config/axios";
import AdminContext from "context/admin/adminContext";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";
import Switch from "react-switch";

function TableCursos() {
  const adminContext = useContext(AdminContext);
  const { formatImporte, modalBajaCurso, setModalBajaCurso, checkJWT } =
    adminContext;
  const [datos, setDatos] = useState();
  const [loadingCursos, setLoadingCursos] = useState(true);
  const [datosModal, setDatosModal] = useState(null);
  const [pais, setPais] = useState("Argentina");
  const [loadingBaja, setLoadingBaja] = useState(false);
  const [datosAlert, setDatosAlert] = useState({
    bgColor: "green-500",
    message: "",
    show: false,
  });

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  useEffect(() => {
    axiosClient
      .get("cursos_all?inactivos=True&pais=" + pais)
      .then((response) => {
        if (response.status === 200) {
          setDatos(response.data.cursos);
          //setHabilitarEdicion(false);
          setLoadingCursos(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pais]);

  function bajaHandler(id_curso) {
    setLoadingBaja(true);
    setLoadingCursos(true);
    axiosClient
      .delete("cursos/" + id_curso, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let categorias_sin_deleted = datos.filter((p) => p.id !== id_curso);
          setDatos(categorias_sin_deleted);
          setDatosAlert({
            bgColor: "green-500",
            message: "Curso dado de baja correctamente",
            show: true,
          });
          setLoadingCursos(false);
          setModalBajaCurso(false);
          setLoadingBaja(false);
        } else {
          setDatosAlert({
            bgColor: "red-500",
            message: "Hubo un error dando de baja el Curso",
            show: true,
          });
          setLoadingCursos(false);
          setModalBajaCurso(false);
          setLoadingBaja(false);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setDatosAlert({
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error dando de baja el Curso",
          show: true,
        });
        setLoadingCursos(false);
        setModalBajaCurso(false);
        setLoadingBaja(false);
        console.log(err);
      });
  }

  const sincronizarCursos = () => {
    setLoadingCursos(true);
    axiosClient
      .post(
        "cursos/sincronizar",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        axiosClient
          .get("cursos_all?inactivos=True")
          .then((response) => {
            if (response.status === 200) {
              setDatos(response.data.cursos);
              //setHabilitarEdicion(false);
              setLoadingCursos(false);
            } else {
              console.log("error");
              //setAlert("Hubo un error actualizando tus datos. Por favor, intentÃ¡ mÃ¡s tarde","red-300");
              setLoadingCursos(false);
            }
            //setActualizando(false);
          })
          .catch((err) => {
            console.log(err);
            //setAlert("Hubo un error actualizando tus datos. Por favor, intentÃ¡ mÃ¡s tarde","red-300");
            //setActualizando(false);
            setLoadingCursos(false);
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        //Notificar al Usuario del Error
        setLoadingCursos(false);
      });
  };

  const filtrarCursos = (e) => {
    setLoadingCursos(true);
    axiosClient
      .get("cursos_all?inactivos=True&nombre=" + e.target.value)
      .then((response) => {
        if (response.status === 200) {
          console.log();
          setDatos(response.data.cursos);
          //setHabilitarEdicion(false);
          setLoadingCursos(false);
        } else {
          console.log("error");
          //setAlert("Hubo un error actualizando tus datos. Por favor, intentÃ¡ mÃ¡s tarde","red-300");
          setLoadingCursos(false);
        }
        //setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        //setAlert("Hubo un error actualizando tus datos. Por favor, intentÃ¡ mÃ¡s tarde","red-300");
        //setActualizando(false);
        setLoadingCursos(false);
      });
  };

  useEffect(() => {
    if (datosModal) {
      setModalBajaCurso(true);
    }
  }, [datosModal]);

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCurso(false);
  };

  const bajaCurso = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  const updateSelect = (value) => {
    setPais(value);
  };

  return (
    <>
      {datosAlert.show ? (
        <Alert
          bgColor={datosAlert.bgColor}
          message={datosAlert.message}
          funcionCierre={() => setDatosAlert({ ...datosAlert, show: false })}
        />
      ) : null}
      <div className="rounded shadow-lg text-left mt-4 border-2 bg-white border-gray-900 pt-6">
        <div className="flex flex-col lg:flex-row justify-between p-4 pt-0 pb-0 items-center">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">
              {" "}
              Listado de Cursos
            </h2>
            <p className=" pt-2 text-gray-500">
              Acá podés revisar el detalle de todos los Cursos
            </p>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-1/2 w-full lg:gap-2 lg:justify-end">
            <div className="flex flex-col">
              <select
                name="pais"
                value={pais}
                onChange={(e) => updateSelect(e.target.value)}
                className=" rounded m-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="Argentina">Argentina</option>
                <option value="Mexico">Mexico</option>
                <option value="Chile">Chile</option>
                <option value="Peru">Peru</option>
              </select>
            </div>

            <div className="mt-6 mb-2 lg:mt-0 lg:mb-6 bg-gray-100 px-4 py-2 text-gray-800 font-medium text-lg rounded flex flex-row justify-start lg:justify-center items-center gap-2 placeholder-gray-500 border border-gray-700 ">
              <svg className="w-7 h-7" viewBox="0 0 24 24">
                <g fill="currentColor">
                  <path d="M11 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4zm-4 2a4 4 0 1 1 7.446 2.032l1.761 1.76a1 1 0 0 1-1.414 1.415l-1.761-1.761A4 4 0 0 1 7 11z"></path>
                  <path d="M12 4a8 8 0 1 0 0 16a8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12z"></path>
                </g>
              </svg>
              <input
                placeholder="Buscar curso"
                onChange={(e) => filtrarCursos(e)}
                className="bg-gray-100 text-gray-800 focus:outline-none focus:ring-0 font-medium placeholder-gray-500"
              />
            </div>
            <button
              className="mb-6 bg-indigo-500 hover:bg-indigo-600 transition duration-300 px-6 py-2 text-white font-medium text-lg rounded flex flex-row justify-center items-center gap-2"
              onClick={() => sincronizarCursos()}
            >
              <svg className="w-7 h-7" viewBox="0 0 24 24">
                <path
                  d="M12 16c.41 0 .81-.03 1.21-.06c.19-.76.51-1.48.95-2.11c-.69.11-1.42.17-2.16.17c-2.42 0-4.7-.6-6-1.55V9.64c1.47.83 3.61 1.36 6 1.36s4.53-.53 6-1.36v1.55c.5-.12 1-.19 1.55-.19c.15 0 .3 0 .45.03V7c0-2.21-3.58-4-8-4S4 4.79 4 7v10c0 2.21 3.59 4 8 4c.66 0 1.31-.04 1.92-.12c-.35-.59-.61-1.24-.76-1.94c-.37.06-.75.06-1.16.06c-3.87 0-6-1.5-6-2v-2.23c1.61.78 3.72 1.23 6 1.23m0-11c3.87 0 6 1.5 6 2s-2.13 2-6 2s-6-1.5-6-2s2.13-2 6-2m11 12.5c0 .82-.25 1.58-.67 2.21l-1.09-1.09c.17-.34.26-.72.26-1.12A2.5 2.5 0 0 0 19 15v1.5l-2.25-2.25L19 12v1.5c2.21 0 4 1.79 4 4m-4 1l2.25 2.25L19 23v-1.5c-2.21 0-4-1.79-4-4c0-.82.25-1.58.67-2.21l1.09 1.09c-.17.34-.26.72-.26 1.12A2.5 2.5 0 0 0 19 20v-1.5z"
                  fill="currentColor"
                ></path>
              </svg>
              Sincronizar Cursos
            </button>
          </div>
        </div>
        <div className="lg:mt-2 p-4 lg:py-12">
          {!loadingCursos && datos && datos.length > 0 ? (
            datos.map((p) => (
              <div
                className="border rounded p-3 mb-3 flex flex-col justify-between lg:grid lg:grid-cols-6 lg:gap-4"
                key={p.id}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">NOMBRE</p>
                  <p className="text-sm text-gray-600 mt-1 font-medium">
                    {p.nombre}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">CATEGORÍAS</p>
                  <p className="text-sm text-gray-500 mt-1 flex flex-row flex-wrap gap-2">
                    {p.categorias.length > 0 ? (
                      p.categorias.map((cate) => (
                        <span className="bg-indigo-500 text-white rounded px-2">
                          {cate.nombre}
                        </span>
                      ))
                    ) : (
                      <span className="bg-orange-500 text-white rounded px-2">
                        No Posee
                      </span>
                    )}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">ESTADO</p>
                  <span
                    className={`bg-${
                      p.estado === "True" ? "green-500" : "red-500"
                    } text-white rounded px-2 text-sm font-medium`}
                  >
                    {p.estado === "True" ? "Activo" : "Oculto"}
                  </span>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">PRECIO</p>
                  <p className="text-sm text-gray-600 mt-1 font-medium">
                    {formatImporte(p.precio)}
                  </p>
                </div>
                <div className="lg:m-0 mb-2 col-span-2">
                  <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                  <div className="flex flex-col lg:grid lg:grid-cols-2 gap-2 mt-2 lg:mt-1">
                    <Link
                      className="bg-indigo-500 text-white px-4 rounded py-0.5 lg:py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300 text-sm"
                      to={`/admin/edit-curso/${p.id}`}
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10h-2a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8V2m6.78 1a.69.69 0 0 0-.48.2l-1.22 1.21l2.5 2.5L20.8 5.7c.26-.26.26-.7 0-.95L19.25 3.2c-.13-.13-.3-.2-.47-.2m-2.41 2.12L9 12.5V15h2.5l7.37-7.38l-2.5-2.5z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      VER / EDITAR
                    </Link>
                    <button
                      onClick={() => setDatosModal(p)}
                      className="bg-red-500 text-white px-4 rounded py-0.5 lg:py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300 text-sm"
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      BAJA
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : !loadingCursos && !datos.length > 0 ? (
            <div className="rounded-lg bg-purple-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <p className="text-xl font-bold flex flex-row justify-center items-center gap-2">
                <svg className="w-8 h-8 text-purple-800" viewBox="0 0 24 24">
                  <path
                    d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                    fill="currentColor"
                  ></path>
                </svg>
                Aún no hay cursos sincronizados
              </p>
            </div>
          ) : loadingCursos ? (
            <div className="rounded-lg bg-green-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
                <path
                  d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                  opacity=".5"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                  fill="currentColor"
                >
                  <animateTransform
                    attributename="transform"
                    type="rotate"
                    from="0 12 12"
                    to="360 12 12"
                    dur="1s"
                    repeatcount="indefinite"
                  ></animateTransform>
                </path>
              </svg>
            </div>
          ) : null}
        </div>
        {modalBajaCurso && datosModal ? (
          <ModalBaja
            titulo={"Baja de Curso"}
            descripcion={`¿Desea dar de baja el Curso ||'${datosModal.nombre}'|| ?`}
            funcionCierre={cerrarModal}
            funcionAccion={bajaCurso}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TableCursos;
