import React from "react";

// components

import CardMembresias from "components/Cards/CardMembresias.js";

export default function Membresias() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <CardMembresias />
        </div>
      </div>
    </>
  );
}
