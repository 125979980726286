function HealthCheck() {
  return (
    <div>
      <h1>This is the HealthCheck for the IOLA Admin Panel</h1>
      <p>Status: HEALTHY</p>
    </div>
  );
}

export default HealthCheck;
