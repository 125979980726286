import React from "react";

export default function FooterAdmin() {
  return (
    <>
      <footer className="py-4 left-0 right-0 top-0 bottom-0">
        <div className=" px-4">
          <hr className="mb-4 border-b-1 border-blueGray-200" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4">
              <div className="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left">
                Desarrollado para{" "}
                <a
                  href="https://academy.invertironline.com"
                  className="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
                  target="__blank"
                  rel="noreferrer"
                >
                  Invertir Online Academy
                </a>
              </div>
            </div>
            <div className="w-full md:w-8/12 px-4">
              <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                <li>
                  <a
                    href="https://arstack.dev"
                    className="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3"
                    target="__blank"
                    rel="noreferrer"
                  >
                    Powered by Arstack
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
