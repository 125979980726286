import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";

// context

import AdminState from "context/admin/adminState";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import HealthCheck from "./views/healthCheck";

ReactDOM.render(
  <BrowserRouter>
    <AdminState>
      <Switch>
        <Route path="/healthcheck" component={HealthCheck} />
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    </AdminState>
  </BrowserRouter>,
  document.getElementById("root")
);
