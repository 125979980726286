import { useState, useEffect, useContext } from "react";

import axiosClient from "../../config/axios";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AdminContext from "context/admin/adminContext";

function EditarCurso() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [loadingDatos, setLoadingDatos] = useState(false);
  const [estado, setEstado] = useState(true);
  const [actualizando, setActualizando] = useState(false);
  const [actualizandoContenido, setActualizandoContenido] = useState(false);
  const [profesores, setProfesores] = useState([]);
  const [profesoresElegidos, setProfesoresElegidos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriasElegidas, setCategoriasElegidas] = useState([]);
  const [cursoDatos, setCursoDatos] = useState({ link_thankyou: "" });

  const { id_curso } = useParams();

  const frontend_url = process.env.REACT_APP_FRONTEND_URL;

  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const updateValue = (e) => {
    var newDatos = { ...cursoDatos };

    newDatos[e.target.name] = e.target.value;
    setCursoDatos(newDatos);
  };

  const updateSelect = (value, target) => {
    var newDatos = { ...cursoDatos };
    newDatos[target] = value;
    setCursoDatos(newDatos);
  };

  const onSwitchChange = () => {
    var newDatos = { ...cursoDatos };
    setEstado(!estado);
    newDatos["estado"] = !estado;
    setCursoDatos(newDatos);
  };

  const actualizarContenido = () => {
    setActualizandoContenido(true);
    axiosClient
      .put(
        "cursos/" + id_curso + "/contenido",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.code === 200) {
          setAlert({
            ...alert,
            bgColor: "green-500",
            message: "Contenido del curso actualizado exitosamente!",
            show: true,
          });
        } else {
          setAlert({
            ...alert,
            bgColor: "red-500",
            message:
              "Hubo un error actualizando el contenido del curso, intentá más tarde",
            show: true,
          });
        }
        setActualizandoContenido(false);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlert({
          ...alert,
          bgColor: "red-500",
          message:
            "Hubo un error actualizando el contenido del curso, intentá más tarde",
          show: true,
        });
        setActualizandoContenido(false);
        window.scrollTo(0, 0);
      });
  };

  const actualizarDatos = () => {
    setActualizando(true);

    let categorias_str = "";
    categoriasElegidas.map(function (row) {
      if (categorias_str === "") categorias_str = row.value;
      else categorias_str += "," + row.value;
    });

    let profesores_str = "";
    profesoresElegidos.map(function (row) {
      if (profesores_str === "") profesores_str = row.value;
      else profesores_str += "," + row.value;
    });

    var payload = {
      nombre: cursoDatos.nombre,
      estado: cursoDatos.estado,
      boton_checkout: cursoDatos.boton_checkout,
      cantida_lecciones: cursoDatos.cantida_lecciones,
      pais: cursoDatos.pais,
      categorias: categorias_str,
      certificado: cursoDatos.certificado,
      descripcion: cursoDatos.descripcion,
      descripcion_q_aprender: cursoDatos.descripcion_q_aprender,
      descripcion_thankyou: cursoDatos.descripcion_thankyou,
      link_thankyou: cursoDatos.link_thankyou,
      duracion: cursoDatos.duracion,
      evento_checkout: cursoDatos.evento_checkout,
      fecha_fin_descuento: cursoDatos.fecha_fin_descuento,
      imagen_trailer: cursoDatos.imagen_trailer,
      keywords: cursoDatos.keywords,
      link_pago: cursoDatos.link_pago,
      metodos_de_pagos: cursoDatos.metodos_de_pagos,
      modalidad: cursoDatos.modalidad,
      fecha_lanzamiento: cursoDatos.fecha_lanzamiento,
      nivel: cursoDatos.nivel,
      plan_de_estudios: cursoDatos.plan_de_estudios,
      porcentaje_descuento: cursoDatos.porcentaje_descuento,
      precio: cursoDatos.precio,
      profesores: profesores_str,
      seo_descripcion: cursoDatos.seo_descripcion,
      seo_title: cursoDatos.seo_title,
      slug: cursoDatos.slug,
      url_imagen_banner: cursoDatos.url_imagen_banner,
      video_trailer: cursoDatos.video_trailer,
      prioridad: cursoDatos.prioridad,
    };

    axiosClient
      .put("cursos/" + id_curso, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          setAlert({
            ...alert,
            bgColor: "green-500",
            message: "Curso modificado exitosamente!",
            show: true,
          });
          //setHabilitarEdicion(false);
        } else {
          setAlert({
            ...alert,
            bgColor: "red-500",
            message: "Hubo un error editando el curso, intentá más tarde",
            show: true,
          });
        }
        setActualizando(false);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlert({
          ...alert,
          bgColor: "red-500",
          message: "Hubo un error editando el curso, intentá más tarde",
          show: true,
        });
        setActualizando(false);
        window.scrollTo(0, 0);
      });
  };

  useEffect(() => {
    axiosClient
      .get("cursos/" + id_curso)
      .then((response) => {
        if (response.status === 200) {
          setCursoDatos(response.data.curso);
          setEstado(response.data.curso.estado === "True" ? true : false);
          let parsed_select_options = [];
          response.data.curso.categorias.map(function (row) {
            console.log(row);
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setCategoriasElegidas(parsed_select_options);

          parsed_select_options = [];
          response.data.curso.profesores.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setProfesoresElegidos(parsed_select_options);
          //setLoading(false);
          //setHabilitarEdicion(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axiosClient
      .get("categorias")
      .then((response) => {
        if (response.status === 200) {
          let parsed_select_options = [];
          response.data.categorias.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setCategorias(parsed_select_options);
          //setLoading(false);
          //setHabilitarEdicion(false);
        } else {
          console.log("error");
        }
        //setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        //setActualizando(false);
      });

    axiosClient
      .get("profesores/")
      .then((response) => {
        if (response.status === 200) {
          let parsed_select_options = [];
          response.data.profesores.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setProfesores(parsed_select_options);
          //setHabilitarEdicion(false);
        } else {
          console.log("error");
        }
        //setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        //setActualizando(false);
      });
  }, []);

  const handleChangeCategorias = (selectedOption) => {
    setCategoriasElegidas(selectedOption);
  };

  const handleChangeProfesores = (selectedOption) => {
    setProfesoresElegidos(selectedOption);
  };

  const animatedComponents = makeAnimated();

  return (
    <>
      {alert.show ? (
        <div
          className={`bg-${alert.bgColor} mt-4 rounded text-white text-lg px-4 py-2 shadow-lg font-bold flex flex-col lg:flex-row justify-between items-center`}
        >
          <div className="flex flex-row items-center gap-2">
            {alert.bgColor.includes("red") ? (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm-8 56a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0zm8 104a12 12 0 1 1 12-12a12 12 0 0 1-12 12z"
                  fill="currentColor"
                ></path>
              </svg>
            ) : (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24zm49.531 85.79l-58.672 56a8.024 8.024 0 0 1-11.062 0l-29.328-28a8.007 8.007 0 0 1 11.062-11.58l23.797 22.728l53.14-50.727a8.007 8.007 0 0 1 11.063 11.578z"
                  fill="currentColor"
                ></path>
              </svg>
            )}

            {alert.message}
          </div>
          <button
            className="bg-white px-2 py-0.5 font-bold rounded text-gray-600 hover:bg-gray-300 transition duration-300"
            onClick={() => {
              setAlert({ ...alert, show: false });
            }}
          >
            X
          </button>
        </div>
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-4">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Editar curso
        </h1>
        <p className="mb-12 text-gray-600">
          Acá podés modificar un curso, relacionarlo con categorias y
          profesores.
        </p>

        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE
            </label>
            <input
              name="nombre"
              disabled={true}
              value={cursoDatos.nombre}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Nombre"}
              // onChange={(e) => updateValue(e)}
            />
          </div>

          <div className="lg:grid lg:grid-cols-5 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                PRECIO
              </label>
              <input
                name="precio"
                type="number"
                value={cursoDatos.precio}
                className={
                  habilitarEdicion
                    ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                    : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
                }
                placeholder={loadingDatos ? "Cargando..." : "Precio"}
                onChange={(e) => updateValue(e)}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                PRIORIDAD
              </label>
              <input
                name="prioridad"
                type="number"
                value={cursoDatos.prioridad}
                className={
                  habilitarEdicion
                    ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                    : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
                }
                placeholder={loadingDatos ? "Cargando..." : "Prioridad"}
                onChange={(e) => updateValue(e)}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                PAIS
              </label>
              <select
                name="pais"
                value={cursoDatos.pais}
                onChange={(e) => updateSelect(e.target.value, "pais")}
                className=" rounded px-2 py-2 h-10 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="Argentina">Argentina</option>
                <option value="Mexico">Mexico</option>
                <option value="Chile">Chile</option>
                <option value="Peru">Peru</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                NIVEL
              </label>
              <select
                name="nivel"
                value={cursoDatos.nivel}
                onChange={(e) => updateSelect(e.target.value, "nivel")}
                className=" rounded px-2 py-2 h-10 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="Inicial">Inicial</option>
                <option value="Medio">Medio</option>
                <option value="Avanzado">Avanzado</option>
              </select>
            </div>
            <div className="flex flex-col p-2">
              <label className="text-gray-500 text-xs font-medium mb-1">
                ACTIVO
              </label>
              <Switch
                name="estado"
                onChange={onSwitchChange}
                checked={estado}
              />
            </div>
          </div>

          <div className="flex flex-col w-full">
            <label className="text-gray-500 text-xs font-medium mb-2 ">
              DESCRIPCION
            </label>
            <textarea
              name="descripcion"
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 pb-16 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none h-24"
                  : "bg-gray-200 cursor-not-allowed pb-16 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600 h-24"
              }
              value={cursoDatos.descripcion}
              placeholder={loadingDatos ? "Cargando..." : "Descripcion"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="text-gray-500 text-xs font-medium mb-2 ">
              DESCRIPCION QUE APRENDER
            </label>
            <textarea
              name="descripcion_q_aprender"
              value={cursoDatos.descripcion_q_aprender}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 pb-16 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none h-24"
                  : "bg-gray-200 cursor-not-allowed pb-16 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600 h-24"
              }
              placeholder={loadingDatos ? "Cargando..." : "Descripcion"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>

          <div className="flex flex-col col-span-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              LINK DE PAGO
            </label>
            <input
              name="link_pago"
              value={cursoDatos.link_pago}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={false ? "Cargando..." : "Link de pago del curso"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>

          <div className="flex flex-col col-span-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              LINK DE THANK-YOU PAGE
            </label>
            <input
              name="link_thankyou"
              value={
                frontend_url +
                "thank-you-curso/" +
                cursoDatos.link_thankyou
                  .replace(frontend_url + "thank-you-curso/", "")
                  .replace(frontend_url + "thank-you-curso", "")
              }
              onChange={(e) => updateValue(e)}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                false ? "Cargando..." : "Link de thank-you page del curso"
              }
            />
          </div>

          <div className="flex flex-col col-span-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              DESCRIPCION DE THANK-YOU PAGE
            </label>
            <textarea
              name="descripcion_thankyou"
              value={cursoDatos.descripcion_thankyou}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 pb-16 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none h-24"
                  : "bg-gray-200 cursor-not-allowed pb-16 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600 h-24"
              }
              placeholder={
                loadingDatos ? "Cargando..." : "Descripcion thank-you page"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>

          <div className="flex flex-col ">
            <label className="text-gray-500 text-xs font-medium mb-1">
              EVENTO CHECKOUT
            </label>
            <select
              name="evento_checkout"
              value={cursoDatos.evento_checkout}
              onChange={(e) => updateSelect(e.target.value, "evento_checkout")}
              className=" rounded px-2 py-2 h-10 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
            >
              <option value="Compra directa">Compra directa</option>
              <option value="Formulario">Formulario</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              TEXTO BOTON CHECKOUT
            </label>
            <input
              name="boton_checkout"
              value={cursoDatos.boton_checkout}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                false ? "Cargando..." : "Texto boton de checkout del curso"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>

          <div className="lg:grid lg:grid-cols-3 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                FECHA LANZAMIENTO
              </label>
              <input
                name="fecha_lanzamiento"
                type="date"
                value={cursoDatos.fecha_lanzamiento}
                className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={false ? "Cargando..." : "Fecha de lanzamiento"}
                disabled={!habilitarEdicion}
                onChange={(e) => updateValue(e)}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                MODALIDAD
              </label>
              <select
                name="modalidad"
                value={cursoDatos.modalidad}
                onChange={(e) => updateSelect(e.target.value, "modalidad")}
                className=" rounded px-2 py-2 h-10 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="Online">Online</option>
                <option value="On-Demand">On-Demand</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                CERTIFICADO
              </label>
              <input
                name="certificado"
                value={cursoDatos.certificado}
                className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={false ? "Cargando..." : "Descripcion certificado "}
                disabled={!habilitarEdicion}
                onChange={(e) => updateValue(e)}
              />
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                DURACION(en segundos)
              </label>
              <input
                name="duracion"
                type="number"
                value={cursoDatos.duracion}
                className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={false ? "Cargando..." : "Duracion del curso"}
                disabled={!habilitarEdicion}
                onChange={(e) => updateValue(e)}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-gray-500 text-xs font-medium mb-1">
                CANTIDAD DE LECCIONES
              </label>
              <input
                name="cantida_lecciones"
                type="number"
                value={cursoDatos.cantida_lecciones}
                className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={
                  false ? "Cargando..." : "Cantidad de lecciones del curso"
                }
                disabled={!habilitarEdicion}
                onChange={(e) => updateValue(e)}
              />
            </div>
          </div>

          <div className="flex flex-col col-span-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              IMAGEN TRAILER
            </label>
            <input
              name="imagen_trailer"
              value={cursoDatos.imagen_trailer}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                false ? "Cargando..." : "Link de Imagen trailer del curso"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>

          <div className="flex flex-col col-span-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              VIDEO TRAILER
            </label>
            <input
              name="video_trailer"
              value={cursoDatos.video_trailer}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                false ? "Cargando..." : "Link de Video Tutorial del curso"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <div className="flex flex-col col-span-2">
          <label className="text-gray-500 text-xs font-medium mb-1">
            URL IMAGEN BANNER
          </label>
          <input
            name="url_imagen_banner"
            value={cursoDatos.url_imagen_banner}
            className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
            placeholder={false ? "Cargando..." : "URL imagen banner del curso"}
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>

        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              CATEGORIAS
            </label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={handleChangeCategorias}
              isMulti
              placeholder={"Elegí una o mas categorias"}
              noOptionsMessage={() => "No se encontraron categorias"}
              value={categoriasElegidas}
              options={categorias}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              PROFESORES
            </label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={handleChangeProfesores}
              isMulti
              placeholder={"Elegí una o mas profesores"}
              noOptionsMessage={() => "No se encontraron profesores"}
              value={profesoresElegidos}
              options={profesores}
            />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              SEO TITLE
            </label>
            <input
              name="seo_title"
              value={cursoDatos.seo_title}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={false ? "Cargando..." : "Titulo SEO del curso"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              SEO DESCRIPCION
            </label>
            <input
              name="seo_descripcion"
              value={cursoDatos.seo_descripcion}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={false ? "Cargando..." : "Descripcion SEO del curso"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              SLUG
            </label>
            <input
              name="slug"
              value={cursoDatos.slug}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={false ? "Cargando..." : "Slug del curso"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              KEYWORDS(separadas por coma)
            </label>
            <input
              name="keywords"
              value={cursoDatos.keywords}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={false ? "Cargando..." : "Keywords del curso"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              PORCENTAJE DESCUENTO
            </label>
            <input
              name="porcentaje_descuento"
              type="number"
              value={cursoDatos.porcentaje_descuento}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                false ? "Cargando..." : "Porcentaje de descuento general"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              FECHA FIN DE DESCUENTO
            </label>
            <input
              name="fecha_fin_descuento"
              type="date"
              value={cursoDatos.fecha_fin_descuento}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                false ? "Cargando..." : "Fecha fin de descuento general"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <div className="flex flex-col col-span-2 mb-4">
          <label className="text-gray-500 text-xs font-medium mb-1">
            PLAN DE ESTUDIO
          </label>
          <input
            name="plan_de_estudios"
            value={cursoDatos.plan_de_estudios}
            className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
            placeholder={
              false ? "Cargando..." : "URL plan de estudios del curso"
            }
            disabled={!habilitarEdicion}
            onChange={(e) => updateValue(e)}
          />
        </div>

        <div className="flex flex-row justify-between">
          <button
            onClick={() => {
              if (habilitarEdicion) {
                actualizarContenido();
              } else {
                setHabilitarEdicion(!habilitarEdicion);
              }
            }}
            className="bg-blue-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-blue-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
            disabled={actualizandoContenido}
          >
            {habilitarEdicion ? (
              actualizandoContenido ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            )}
            <p className="mb-0.5">
              {" "}
              {habilitarEdicion
                ? actualizandoContenido
                  ? "Actualizando..."
                  : "Actualizar contenido"
                : "actualizar"}
            </p>
          </button>

          <button
            onClick={() => {
              if (habilitarEdicion) {
                actualizarDatos();
              } else {
                setHabilitarEdicion(!habilitarEdicion);
              }
            }}
            className={
              habilitarEdicion
                ? actualizando
                  ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
                  : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
                : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
            }
            disabled={actualizando}
          >
            {habilitarEdicion ? (
              actualizando ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            )}
            <p className="mb-0.5">
              {" "}
              {habilitarEdicion
                ? actualizando
                  ? "Actualizando"
                  : "Guardar Cambios"
                : "Editar"}
            </p>
          </button>
        </div>
      </div>
    </>
  );
}

export default EditarCurso;
