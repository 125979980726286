/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            IOLA - Administración
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Menú Principal
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex flex-row items-center " +
                    (window.location.href.indexOf("/admin/banners") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/banners"
                >
                  <svg
                    className={
                      "w-5 h-5 mr-3 text-sm " +
                      (window.location.href.indexOf("/admin/banners") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                    viewBox="0 0 24 24"
                  >
                    <path
                      class="uim-tertiary"
                      opacity=".5"
                      d="M9 10h6v12H9z"
                      fill="currentColor"
                    ></path>
                    <path
                      class="uim-tertiary"
                      d="M2 10v11a1 1 0 0 0 1 1h4V10H2z"
                      opacity=".5"
                      fill="currentColor"
                    ></path>
                    <path
                      class="uim-quaternary"
                      opacity=".25"
                      d="M22 8H2v2h5v12h2V10h6v12h2V10h5z"
                      fill="currentColor"
                    ></path>
                    <path
                      class="uim-tertiary"
                      d="M17 10v12h4a1 1 0 0 0 1-1V10h-5z"
                      opacity=".5"
                      fill="currentColor"
                    ></path>
                    <path
                      class="uim-primary"
                      d="M3 2h18a1 1 0 0 1 1 1v5H2V3a1 1 0 0 1 1-1z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Banners
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/list-categorias") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-categorias"
                >
                  <i
                    className={
                      "fas fa-tag mr-3 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/list-categorias"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Categorias
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/settings") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/settings"
                >
                  <i
                    className={
                      "fas fa-tools mr-3 text-sm " +
                      (window.location.href.indexOf("/admin/settings") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Configuración
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/list-cursos") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-cursos"
                >
                  <i
                    className={
                      "fas fa-graduation-cap mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/list-cursos") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Cursos
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/membresias") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/membresias"
                >
                  <i
                    className={
                      "fas fa-solid fa-credit-card mr-3 text-sm " +
                      (window.location.href.indexOf("/admin/membresias") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Membresias
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex flex-row items-center " +
                    (window.location.href.indexOf(
                      "/admin/list-preguntas-usuarios"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-preguntas-usuarios"
                >
                  <svg
                    className={
                      "w-5 h-5 mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/list-preguntas-usuarios"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44c.91-1.29.4-3.7-2.18-3.7c-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41c.7 1.15 1.11 3.3.03 4.9c-1.2 1.77-2.35 2.31-2.97 3.45c-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15zM14 20c0 1.1-.9 2-2 2s-2-.9-2-2s.9-2 2-2s2 .9 2 2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Preguntas de usuarios
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex flex-row items-center " +
                    (window.location.href.indexOf(
                      "/admin/list-preguntas-frecuentes"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-preguntas-frecuentes"
                >
                  <svg
                    className={
                      "w-5 h-5 mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/list-preguntas-frecuentes"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44c.91-1.29.4-3.7-2.18-3.7c-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41c.7 1.15 1.11 3.3.03 4.9c-1.2 1.77-2.35 2.31-2.97 3.45c-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15zM14 20c0 1.1-.9 2-2 2s-2-.9-2-2s.9-2 2-2s2 .9 2 2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Preguntas Frecuentes
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/list-profe") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-profe"
                >
                  <i
                    className={
                      "fas fa-chalkboard-teacher mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/list-profe") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Profesores
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/list-programas") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-programas"
                >
                  <i
                    className={
                      "fas fa-layer-group mr-3 text-sm " +
                      (window.location.href.indexOf("/admin/list-programas") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Programas
                </Link>
              </li>

              <li className="items-center ml-1">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold flex flex-row items-center " +
                    (window.location.href.indexOf("/admin/list-reviews") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/list-reviews"
                >
                  <i
                    className={
                      "fas fa-check mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/list-reviews") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Reviews
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            {/* Divider */}
            {/*  <hr className="my-4 md:min-w-full" /> */}
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Links Útiles
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="inline-flex">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/maps") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  to={{ pathname: process.env.REACT_APP_FRONTEND_URL }}
                >
                  <i
                    className={
                      "fas fa-link mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/maps") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  IOLA - Landing
                </Link>
              </li>

              <li className="inline-flex">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/maps") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  to={{
                    pathname: process.env.REACT_APP_BACKEND_URL + "/redoc",
                  }}
                >
                  <i
                    className={
                      "fas fa-link mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/maps") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  IOLA - API
                </Link>
              </li>
            </ul>
            <hr className="my-4 md:min-w-full" />
            {localStorage.getItem("username") == "admin" ? (
              <div>
                <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Usuarios
                </h6>
                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                  <li className="inline-flex">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/list-user") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/list-user"
                    >
                      <i
                        className={
                          "fas fa-users mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/list-user") !==
                          -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      USUARIOS REGISTRADOS
                    </Link>
                  </li>

                  <li className="inline-flex">
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/add-user") !== -1
                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                          : "text-blueGray-700 hover:text-blueGray-500")
                      }
                      to="/admin/add-user"
                    >
                      <i
                        className={
                          "fas fa-user-plus mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/add-user") !==
                          -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      AGREGAR USUARIO
                    </Link>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
}
