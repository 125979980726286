import React from "react";
import PropTypes from "prop-types";

// components

export default function CardTable({ color }) {
  return (
    <div>
      <div
        className={
          "relative flex flex-col min-w-0 mt-6 break-words w-full mb-6 shadow-lg rounded border-gray-900 border-2 my-12 py-28 " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-start">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-center">
              <h3
                className={
                  "font-semibold text-2xl text-center" +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                ¡Hola, Admin! Bienvenido al Panel de Administración
              </h3>
              <div className="flex justify-center mt-4">
                <p className="font-medium text-lg text-center w-3/4">
                  {" "}
                  En este Panel de Administración vas a poder configurar por
                  completo toda la Landing Page de Invertir Online Academy.
                  Empezando por la configuración básica, y luego pasando por los
                  Banners, los Cursos y los Profesores.
                </p>
              </div>

              <div className="flex mt-4">
                <p className="font-medium text-lg text-center">
                  {" "}
                  - Los cursos se sincronizan con Thinkific(TK), si el curso ya
                  existe se le sincroniza el nombre, en caso de no existir se
                  crea el nuevo curso con estado inactivo.
                </p>
              </div>
              <div className="flex mt-4">
                <p className="font-medium text-lg text-center">
                  {" "}
                  - Al cargar un curso se debe actualizar su contenido, esto es
                  desde el detalle del mismo. Si es modificado el contenido en
                  TK con presionar actualizar contenido se sincroniza.
                </p>
              </div>
              <div className="flex mt-4">
                <p className="font-medium text-lg text-center">
                  {" "}
                  - La oferta individual de curso o membresia tiene prioridad
                  sobre la oferta general, en caso de la oferta estar en 0 o si
                  la fecha paso no aplica la oferta .
                </p>
              </div>
              <div className="flex mt-4">
                <p className="font-medium text-lg text-center">
                  {" "}
                  - La oferta general no aplica a los programas, el descuento en
                  ellos es de forma individual .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
