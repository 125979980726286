import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../config/axios";
import { Link } from "react-router-dom";
import AdminContext from "context/admin/adminContext";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";
import Switch from "react-switch";

function TablePreguntas() {
  const adminContext = useContext(AdminContext);
  const { modalBajaCate, setModalBajaCate, checkJWT } = adminContext;

  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datosModal, setDatosModal] = useState(null);
  const [seccion, setSeccion] = useState("all");
  const [sinResponder, setSinResponder] = useState(true);
  const [loadingBaja, setLoadingBaja] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [cursos, setCursos] = useState([]);
  const [programas, setProgramas] = useState([]);
  const [alertData, setAlertData] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });

  useEffect(() => {
    axiosClient
      .get("cursos_all")
      .then((response) => {
        if (response.status === 200) {
          let parsed_select_options = [];
          response.data.cursos.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setCursos(parsed_select_options);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axiosClient
      .get("programas")
      .then((response) => {
        if (response.status === 200) {
          let parsed_select_options = [];
          response.data.programas.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setProgramas(parsed_select_options);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  function bajaHandler(id_pregunta) {
    setLoadingBaja(true);
    axiosClient
      .delete("preguntas_frecuentes/" + id_pregunta, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let preguntas_sin_deleted = datos.filter((p) => p.id !== id_pregunta);
          setAlertData({
            bgColor: "green-500",
            message: "Se dió de baja la pregunta correctamente",
            show: true,
          });
          setModalBajaCate(false);
          setDatos(preguntas_sin_deleted);
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
        } else {
          setModalBajaCate(false);
          console.log("error");
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
          setAlertData({
            bgColor: "red-500",
            message:
              "Hubo un error dando de baja la pregunta, intentá más tarde",
            show: true,
          });
          //setAlert("Hubo un error actualizando tus datos. Por favor, intenta ma tarde","red-300");
        }
        //setActualizando(false);
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlertData({
          bgColor: "red-500",
          message: "Hubo un error dando de baja la pregunta, intentá más tarde",
          show: true,
        });
        setModalBajaCate(false);
        console.log(err);
        setLoadingBaja(false);
        //setAlert("Hubo un error actualizando tus datos. Por favor, intenta ma tarde","red-300");
      });
  }

  //setActualizando(true);
  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(
        "preguntas_frecuentes/?seccion=" +
          seccion +
          "&no_respuesta=" +
          sinResponder
      )
      .then((response) => {
        if (response.status === 200) {
          setDatos(response.data.preguntas_frecuentes.items);
          setLoading(false);
          //setHabilitarEdicion(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [seccion, sinResponder]);

  useEffect(() => {
    if (datosModal) {
      setModalBajaCate(true);
    }
  }, [datosModal]);

  const updateSelect = (value) => {
    setSeccion(value);
    console.log(value);
  };

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCate(false);
  };

  const darDeBajaCate = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  const onSwitchChange = () => {
    setSinResponder(!sinResponder);
  };

  return (
    <>
      {alertData.show ? (
        <Alert
          bgColor={alertData.bgColor}
          message={alertData.message}
          funcionCierre={() => {
            setAlertData({ ...alertData, show: false });
          }}
        />
      ) : null}

      <div className="rounded-lg shadow-lg text-left mt-4 border-2 bg-white border-gray-900 pt-6 ">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 justify-between p-4 pt-0 pb-0 items-center">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">
              {" "}
              Listado de Preguntas de Usuarios
            </h2>
            <p className=" pt-2 text-gray-500">
              Acá podés responder y administrar las preguntas de los usuarios.
            </p>
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col px-2">
              <label className="text-gray-500 text-xs font-medium mb-1">
                SIN RESPONDER
              </label>
              <Switch
                name="sinResponder"
                className="mt-2"
                onChange={onSwitchChange}
                checked={sinResponder}
              />
            </div>
            <div className="flex flex-col px-2">
              <label className="text-gray-500 text-xs font-medium mb-1">
                SECCION
              </label>
              <select
                name="seccion"
                value={seccion}
                onChange={(e) => updateSelect(e.target.value)}
                className=" rounded m-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="all">Todas</option>
                <option disabled>Programas</option>
                {programas.map((p) => (
                  <option value={"programa-" + p.value}>{p.label}</option>
                ))}
                <option disabled>Cursos</option>
                {cursos.map((p) => (
                  <option value={"curso-" + p.value}>{p.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className=" p-4 py-12">
          {!loading && datos.length > 0 ? (
            datos.map((p) => (
              <div
                className="border rounded p-3 mb-3 flex flex-col justify-between lg:grid lg:grid-cols-2 lg:gap-4"
                key={p.id}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">PREGUNTA</p>
                  <p className="text-sm text-gray-600 mt-1 font-medium">
                    {p.pregunta}
                  </p>
                </div>
                <div className="lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                  <div className="lg:grid lg:grid-cols-2 flex flex-col gap-2 text-sm lg:text-md mt-1">
                    <Link
                      className="bg-indigo-500 text-white px-4 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300 w-full text-sm"
                      to={`/admin/responder-pregunta/${p.id}`}
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10h-2a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8V2m6.78 1a.69.69 0 0 0-.48.2l-1.22 1.21l2.5 2.5L20.8 5.7c.26-.26.26-.7 0-.95L19.25 3.2c-.13-.13-.3-.2-.47-.2m-2.41 2.12L9 12.5V15h2.5l7.37-7.38l-2.5-2.5z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      RESPONDER
                    </Link>
                    <button
                      onClick={() => setDatosModal(p)}
                      className="bg-red-500 text-white px-4 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300 w-full text-sm"
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      BAJA
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : loading ? (
            <div className="rounded-lg bg-green-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
                <path
                  d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                  opacity=".5"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                  fill="currentColor"
                >
                  <animateTransform
                    attributename="transform"
                    type="rotate"
                    from="0 12 12"
                    to="360 12 12"
                    dur="1s"
                    repeatcount="indefinite"
                  ></animateTransform>
                </path>
              </svg>
            </div>
          ) : !datos.length > 0 ? (
            <div className="rounded-lg bg-purple-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <p className="text-xl font-bold flex flex-row justify-center items-center gap-2">
                <svg className="w-8 h-8 text-purple-800" viewBox="0 0 24 24">
                  <path
                    d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                    fill="currentColor"
                  ></path>
                </svg>
                Aún no hay preguntas de usuarios dadas de alta
              </p>
            </div>
          ) : null}
        </div>
        {datosModal && modalBajaCate ? (
          <ModalBaja
            titulo={"Baja de Pregunta"}
            descripcion={`¿ Desea dar de baja la Pregunta de usuario ?`}
            funcionCierre={cerrarModal}
            funcionAccion={darDeBajaCate}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TablePreguntas;
