import React, { useState, useEffect, useContext } from "react";
import AdminContext from "context/admin/adminContext";
import makeAnimated from "react-select/animated";
import Select from "react-select";

// components
import axiosClient from "../../config/axios";
export default function CardSettings() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;
  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [actualizando, setActualizando] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [cursos, setCursos] = useState([]);
  const [cursosElegidas, setCursosElegidas] = useState([]);
  const [programas, setProgramas] = useState([]);
  const [programasElegidas, setProgramasElegidas] = useState([]);

  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const getToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd;
    return today;
  };

  const [configuracion, setConfiguracion] = useState({
    video_tutorial: "",
    oferta_porcentaje: 0,
    oferta_titulo: "",
    oferta_button: "",
    fecha_fin_descuento: getToday,
    link_membresia_basic: "",
    link_membresia_gold: "",
    precio_membresia_basic: "",
    precio_membresia_gold: "",
    descripcion_membresia_basic: "",
    descripcion_membresia_gold: "",
    descuento_membresia_basic: 0,
    descuento_membresia_gold: 0,
  });

  const actualizarDatos = () => {
    setActualizando(true);

    let cursos_str = "";
    cursosElegidas.map(function (row) {
      if (cursos_str === "") cursos_str = row.value;
      else cursos_str += "," + row.value;
    });

    let programa_str = "";
    programasElegidas.map(function (row) {
      if (programa_str === "") programa_str = row.value;
      else programa_str += "," + row.value;
    });

    var payload = {
      video_tutorial: configuracion.video_tutorial,
      oferta_porcentaje: configuracion.oferta_porcentaje,
      oferta_titulo: configuracion.oferta_titulo,
      oferta_redirect: configuracion.oferta_redirect,
      oferta_button: configuracion.oferta_button,
      cursos: cursos_str,
      programas: programa_str,
      fecha_fin_descuento: configuracion.fecha_fin_descuento,
      link_membresia_basic: configuracion.link_membresia_basic,
      link_membresia_gold: configuracion.link_membresia_gold,
      precio_membresia_basic: configuracion.precio_membresia_basic,
      precio_membresia_gold: configuracion.precio_membresia_gold,
      descuento_membresia_basic: configuracion.descuento_membresia_basic,
      descuento_membresia_gold: configuracion.descuento_membresia_gold,
      descripcion_membresia_basic: configuracion.descripcion_membresia_basic,
      descripcion_membresia_gold: configuracion.descripcion_membresia_gold,
    };
    axiosClient
      .put("configuracion", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          setAlert({
            ...alert,
            bgColor: "green-500",
            message: "Configuracion modificada exitosamente",
            show: true,
          });
          setActualizando(false);
        } else {
          setAlert({
            ...alert,
            bgColor: "red-500",
            message: response.data.error,
            show: true,
          });
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlert({
          ...alert,
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error inesperado, intentá nuevamente más tarde",
          show: true,
        });
        setActualizando(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get("cursos_all")
      .then((response) => {
        if (response.status === 200) {
          let parsed_select_options = [];
          response.data.cursos.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setCursos(parsed_select_options);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axiosClient
      .get("programas")
      .then((response) => {
        if (response.status === 200) {
          let parsed_select_options = [];
          response.data.programas.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setProgramas(parsed_select_options);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChangeCursos = (selectedOption) => {
    setCursosElegidas(selectedOption);
  };

  const handleChangeProgramas = (selectedOption) => {
    setProgramasElegidas(selectedOption);
  };

  const animatedComponents = makeAnimated();

  useEffect(() => {
    axiosClient
      .get("configuracion")
      .then((response) => {
        if (response.status === 200) {
          setConfiguracion(response.data.configuracion);
          let parsed_select_options = [];
          response.data.configuracion.cursos.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setCursosElegidas(parsed_select_options);
          parsed_select_options = [];
          response.data.configuracion.programas.map(function (row) {
            parsed_select_options.push({ value: row.id, label: row.nombre });
          });
          setProgramasElegidas(parsed_select_options);
          setLoadingConfig(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateValue = (e) => {
    var newDatos = { ...configuracion };
    newDatos[e.target.name] = e.target.value;
    setConfiguracion(newDatos);
  };

  return (
    <>
      {alert.show ? (
        <div
          className={`bg-${alert.bgColor} mt-4 rounded text-white text-lg px-4 py-2 shadow-lg font-bold flex flex-col lg:flex-row justify-between items-center`}
        >
          <div className="flex flex-row items-center gap-2">
            {alert.bgColor.includes("red") ? (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm-8 56a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0zm8 104a12 12 0 1 1 12-12a12 12 0 0 1-12 12z"
                  fill="currentColor"
                ></path>
              </svg>
            ) : (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24zm49.531 85.79l-58.672 56a8.024 8.024 0 0 1-11.062 0l-29.328-28a8.007 8.007 0 0 1 11.062-11.58l23.797 22.728l53.14-50.727a8.007 8.007 0 0 1 11.063 11.578z"
                  fill="currentColor"
                ></path>
              </svg>
            )}

            {alert.message}
          </div>
          <button
            className="bg-white px-2 py-0.5 font-bold rounded text-gray-600 hover:bg-gray-300 transition duration-300"
            onClick={() => {
              setAlert({ ...alert, show: false });
            }}
          >
            X
          </button>
        </div>
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-4">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Configuración General del Sitio
        </h1>
        <p className="mb-12 text-gray-600">
          Acá podés cambiar la configuración general del sitio, como links,
          aplicar un descuento general y demás
        </p>
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col col-span-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              ID YOUTUBE DE VIDEO TUTORIAL
            </label>
            <input
              name="video_tutorial"
              value={configuracion.video_tutorial}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                loadingConfig
                  ? "Cargando..."
                  : "Id de Youtube del Video Tutorial de la Landing"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <hr></hr>
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col col-span-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              LINK REDIRECCION OFERTA
            </label>
            <input
              name="oferta_redirect"
              value={configuracion.oferta_redirect}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                loadingConfig
                  ? "Cargando..."
                  : "Link de redireccion al presionar boton de oferta"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              TITULO DESCUENTO GENERAL
            </label>
            <input
              name="oferta_titulo"
              value={configuracion.oferta_titulo}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                loadingConfig ? "Cargando..." : "Titulo de descuento general"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              BOTON DESCUENTO GENERAL
            </label>
            <input
              name="oferta_button"
              value={configuracion.oferta_button}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                loadingConfig
                  ? "Cargando..."
                  : "Contenido boton de descuento general"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              PORCENTAJE DESCUENTO GENERAL
            </label>
            <input
              name="oferta_porcentaje"
              type="number"
              value={configuracion.oferta_porcentaje}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                loadingConfig
                  ? "Cargando..."
                  : "Porcentaje de descuento general"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              FECHA FIN DE DESCUENTO GENERAL
            </label>
            <input
              name="fecha_fin_descuento"
              type="date"
              value={configuracion.fecha_fin_descuento}
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder={
                loadingConfig ? "Cargando..." : "Fecha fin de descuento general"
              }
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
        </div>
        <hr></hr>
        <div className="flex flex-col my-2">
          <label className="text-gray-500 text-xs font-medium mb-1">
            CURSOS FOOTER
          </label>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            onChange={handleChangeCursos}
            isMulti
            placeholder={"Elegí hasta 4 cursos"}
            noOptionsMessage={() => "No se encontraron cursos"}
            value={cursosElegidas}
            options={cursos}
          />
        </div>
        <div className="flex flex-col my-2">
          <label className="text-gray-500 text-xs font-medium mb-1">
            PROGRAMAS FOOTER
          </label>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            onChange={handleChangeProgramas}
            isMulti
            placeholder={"Elegí hasta 4 programas"}
            noOptionsMessage={() => "No se encontraron programas"}
            value={programasElegidas}
            options={programas}
          />
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={() => {
              if (habilitarEdicion) {
                actualizarDatos();
              } else {
                setHabilitarEdicion(!habilitarEdicion);
              }
            }}
            className={
              habilitarEdicion
                ? actualizando
                  ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
                  : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
                : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
            }
            //disabled={actualizando}
          >
            {habilitarEdicion ? (
              actualizando ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            )}
            <p className="mb-0.5">
              {" "}
              {habilitarEdicion
                ? actualizando
                  ? "Actualizando"
                  : "Guardar Cambios"
                : "Editar"}
            </p>
          </button>
        </div>
      </div>
    </>
  );
}
