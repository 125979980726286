import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import TableBanners from "views/admin/TableBanners.js";
import Settings from "views/admin/Settings.js";
import Membresias from "views/admin/Membresias.js";
import FormUsuario from "views/admin/FormUsuario.js";
import FormCategoria from "views/admin/FormCategoria.js";
import EditarCategoria from "views/admin/EditarCategoria.js";
import EditarCurso from "views/admin/EditarCurso.js";
import EditarUsuario from "views/admin/EditarUsuario.js";
import TableUsuario from "views/admin/TableUsuario.js";
import TableCategorias from "views/admin/TableCategorias.js";
import TableProgramas from "views/admin/TableProgramas.js";
import EditarPrograma from "views/admin/EditarPrograma.js";
import FormPrograma from "views/admin/FormPrograma.js";
import FormBanner from "views/admin/FormBanner.js";
import FormProfesor from "views/admin/FormProfesor.js";
import EditarProfesor from "views/admin/EditarProfesor.js";
import TableProfesor from "views/admin/TableProfesor.js";
import TableCursos from "views/admin/TableCursos";
import EditBanner from "views/admin/EditBanner";
import TablePreguntasFrecuentes from "views/admin/TablePreguntasFrecuentes";
import TablePreguntas from "views/admin/TablePreguntas";
import TableReviews from "views/admin/TableReviews";
import EditarReview from "views/admin/EditarReview";
import FormPreguntaFrecuente from "views/admin/FormPreguntaFrecuente";
import EditarPreguntaFrecuente from "views/admin/EditarPreguntaFrecuente";
import ResponderPregunta from "views/admin/ResponderPregunta";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />

        <div className="px-4 md:px-10 mx-auto w-full h-screen flex flex-col gap-4">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/banners" exact component={TableBanners} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/membresias" exact component={Membresias} />
            <Route path="/admin/list-user" exact component={TableUsuario} />
            <Route path="/admin/add-user" exact component={FormUsuario} />
            <Route
              path="/admin/edit-user/:id_usuario"
              exact
              component={EditarUsuario}
            />
            <Route path="/admin/list-profe" exact component={TableProfesor} />
            <Route path="/admin/add-profe" exact component={FormProfesor} />
            <Route
              path="/admin/edit-profesor/:id_profesor"
              exact
              component={EditarProfesor}
            />
            <Route path="/admin/list-cursos" exact component={TableCursos} />
            <Route
              path="/admin/edit-curso/:id_curso"
              exact
              component={EditarCurso}
            />
            <Route
              path="/admin/list-categorias"
              exact
              component={TableCategorias}
            />
            <Route
              path="/admin/add-categoria"
              exact
              component={FormCategoria}
            />
            <Route
              path="/admin/edit-categoria/:id_categoria"
              exact
              component={EditarCategoria}
            />
            <Route
              path="/admin/list-programas"
              exact
              component={TableProgramas}
            />
            <Route path="/admin/add-programa" exact component={FormPrograma} />
            <Route
              path="/admin/edit-programa/:id_programa"
              exact
              component={EditarPrograma}
            />
            <Route path="/admin/add-banner" exact component={FormBanner} />
            <Route
              path="/admin/edit-banner/:id_banner"
              exact
              component={EditBanner}
            />
            <Route
              path="/admin/list-preguntas-usuarios"
              exact
              component={TablePreguntas}
            />
            <Route
              path="/admin/list-preguntas-frecuentes"
              exact
              component={TablePreguntasFrecuentes}
            />
            <Route
              path="/admin/add-pregunta-frecuente"
              exact
              component={FormPreguntaFrecuente}
            />
            <Route
              path="/admin/responder-pregunta/:id_pregunta"
              exact
              component={ResponderPregunta}
            />
            <Route
              path="/admin/edit-pregunta-frecuente/:id_pregunta"
              exact
              component={EditarPreguntaFrecuente}
            />

            <Route path="/admin/list-reviews" exact component={TableReviews} />
            <Route
              path="/admin/edit-review/:id_review"
              exact
              component={EditarReview}
            />

            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
