import React, { useEffect, useState, useContext } from "react";
import AdminContext from "context/admin/adminContext";
import axiosClient from "../../config/axios";
import { Link } from "react-router-dom";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";

function TableUsuario() {
  const adminContext = useContext(AdminContext);
  const { modalBajaCate, setModalBajaCate } = adminContext;
  const [loadingBaja, setLoadingBaja] = useState(false);
  const { checkJWT } = adminContext;
  const [datosModal, setDatosModal] = useState(null);
  const [alertData, setAlertData] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  function bajaHandler(id_usuario) {
    axiosClient
      .delete("usuarios/" + id_usuario, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let usuarios_sin_deleted = datos.filter((p) => p.id !== id_usuario);
          setDatos(usuarios_sin_deleted);
          cerrarModal();
          setAlertData({
            bgColor: "green-500",
            message: "Se dió de baja el usuario correctamente",
            show: true,
          });
        } else {
          console.log("error");
        }
        //setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCate(false);
  };

  const darDeBajaCate = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  //setActualizando(true);
  useEffect(() => {
    axiosClient
      .get("usuarios/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setDatos(response.data.usuarios);
          setLoading(false);
          //setHabilitarEdicion(false);
        } else {
          console.log("error");
        }
        //setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        //setActualizando(false);
      });
  }, []);

  return (
    <>
      {alertData.show ? (
        <Alert
          bgColor={alertData.bgColor}
          message={alertData.message}
          funcionCierre={() => {
            setAlertData({ ...alertData, show: false });
          }}
        />
      ) : null}
      <div className="rounded-lg shadow-lg text-left mt-4 border-2 bg-white border-gray-900 pt-6 ">
        <h2 class="text-2xl font-semibold leading-tight pl-4">
          {" "}
          Usuarios Registrados
        </h2>
        <p className="p-4 pb-0 pt-2 text-gray-500">
          Estos son los usuarios registrados que tienen acceso al panel de
          administración
        </p>
        <div className=" p-4 py-12">
          {!loading && datos.length > 0 ? (
            datos.map((p) => (
              <div
                className="border rounded p-3 mb-3 flex flex-col justify-between lg:grid lg:grid-cols-4 lg:gap-4"
                key={p.id}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">
                    NOMBRE DE USUARIO
                  </p>
                  <p className="text-sm lg:text-md text-gray-500 lg:mt-2">
                    {p.username}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">EMAIL</p>
                  <p className="text-sm lg:text-md text-gray-500 lg:mt-2">
                    {p.email}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">ESTADO</p>
                  <p className="text-sm lg:text-md bg-green-500 rounded-lg text-white px-2 font-medium mt-1.5 w-3/12 text-center">
                    ACTIVO
                  </p>
                </div>
                <div className="lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                  <div className="lg:grid lg:grid-cols-2 gap-2 text-sm lg:text-md mt-1">
                    <Link className="" to={`/admin/edit-user/${p.id}`}>
                      <button className="bg-indigo-500 text-white px-4 rounded py-0.5 lg:py-1 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300">
                        <svg className="w-4 h-4" viewBox="0 0 24 24">
                          <path
                            d="M12 0a12 12 0 0 1 12 12a12 12 0 0 1-12 12A12 12 0 0 1 0 12A12 12 0 0 1 12 0m0 2A10 10 0 0 0 2 12c0 2.4.85 4.6 2.26 6.33L18.33 4.26A9.984 9.984 0 0 0 12 2m0 20a10 10 0 0 0 10-10c0-2.4-.85-4.6-2.26-6.33L5.67 19.74A9.984 9.984 0 0 0 12 22z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        VER / EDITAR
                      </button>
                    </Link>
                    <button
                      onClick={() => setDatosModal(p)}
                      className="bg-red-500 text-white px-4 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300"
                    >
                      <svg className="w-4 h-4" viewBox="0 0 16 16">
                        <g fill="none">
                          <path
                            d="M9.5 9a1.5 1.5 0 0 1 1.5 1.5v.5c0 1.971-1.86 4-5 4c-3.14 0-5-2.029-5-4v-.5A1.5 1.5 0 0 1 2.5 9h7zM6 2.5A2.75 2.75 0 1 1 6 8a2.75 2.75 0 0 1 0-5.5zm4.784-1.411l.07.057L12.5 2.793l1.646-1.647a.5.5 0 0 1 .708.708L13.207 3.5l1.647 1.646a.5.5 0 0 1 .057.638l-.057.07a.5.5 0 0 1-.638.057l-.07-.057L12.5 4.207l-1.646 1.647a.5.5 0 0 1-.708-.708L11.793 3.5l-1.647-1.646a.5.5 0 0 1-.057-.638l.057-.07a.5.5 0 0 1 .562-.1l.076.043z"
                            fill="currentColor"
                          ></path>
                        </g>
                      </svg>
                      BAJA
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="rounded-lg bg-green-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
                <path
                  d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                  opacity=".5"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                  fill="currentColor"
                >
                  <animateTransform
                    attributename="transform"
                    type="rotate"
                    from="0 12 12"
                    to="360 12 12"
                    dur="1s"
                    repeatcount="indefinite"
                  ></animateTransform>
                </path>
              </svg>
            </div>
          )}
        </div>
        {datosModal ? (
          <ModalBaja
            titulo={"Baja de Usuario"}
            descripcion={`¿ Desea dar de baja el Usuario ?`}
            funcionCierre={cerrarModal}
            funcionAccion={darDeBajaCate}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TableUsuario;
