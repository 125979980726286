import { useState, useEffect, useContext } from "react";
import axiosClient from "../../config/axios";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import AdminContext from "context/admin/adminContext";

function EditBanner() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [estado, setEstado] = useState(true);
  const [es_empresa, setEsEmpresas] = useState(true);
  const [fondo_imagen, setFondoImagen] = useState(true);

  const [actualizando, setActualizando] = useState(false);

  const [bannerDatos, setBannerDatos] = useState({});

  const { id_banner } = useParams();

  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const updateValue = (e) => {
    var newDatos = { ...bannerDatos };
    newDatos[e.target.name] = e.target.value;
    setBannerDatos(newDatos);
  };

  const onSwitchChangeEstado = () => {
    var newDatos = { ...bannerDatos };
    setEstado(!estado);
    newDatos["estado"] = !estado;
    setBannerDatos(newDatos);
  };

  const updateSelect = (value, target) => {
    var newDatos = { ...bannerDatos };
    newDatos[target] = value;
    setBannerDatos(newDatos);
  };

  const onSwitchChangeFondoImagen = () => {
    var newDatos = { ...bannerDatos };
    setFondoImagen(!fondo_imagen);
    newDatos["fondo_imagen"] = !fondo_imagen;
    setBannerDatos(newDatos);
  };

  const actualizarDatos = () => {
    setActualizando(true);

    var payload = {
      titulo: bannerDatos.titulo,
      subtitulo: bannerDatos.subtitulo,
      texto_boton: bannerDatos.texto_boton,
      estado: estado,
      es_empresa: es_empresa,
      imagen: bannerDatos.imagen,
      fondo_imagen: fondo_imagen,
      fondo_imagen_desktop: bannerDatos.fondo_imagen_desktop,
      fondo_imagen_movil: bannerDatos.fondo_imagen_movil,
      comentario: bannerDatos.comentario,
      color: bannerDatos.color,
      color_texto: bannerDatos.color_texto,
      color_link: bannerDatos.color_link,
      seccion: bannerDatos.seccion ?? "HOME",
      redirect_link: bannerDatos.redirect_link,
    };

    axiosClient
      .put("banner/" + id_banner, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          setAlert({
            ...alert,
            bgColor: "green-500",
            message: "Banner modificado exitosamente!",
            show: true,
          });

          //setHabilitarEdicion(false);
        } else {
          setAlert({
            ...alert,
            bgColor: "red-500",
            message: "Hubo un error modificando el banner, intentá más tarde",
            show: true,
          });
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlert({
          ...alert,
          bgColor: "red-500",
          message: "Hubo un error modificando el banner, intentá más tarde",
          show: true,
        });
        setActualizando(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get("banner/" + id_banner)
      .then((response) => {
        if (response.status === 200) {
          setBannerDatos(response.data.banner);
          setEstado(response.data.banner.estado === "True" ? true : false);
          setEsEmpresas(
            response.data.banner.es_empresa === "True" ? true : false
          );
          setFondoImagen(
            response.data.banner.fondo_imagen === "True" ? true : false
          );
          //setLoading(false);
          //setHabilitarEdicion(false);
        } else {
          console.log("error");
        }
        //setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        //setActualizando(false);
      });
  }, []);

  return (
    <>
      {alert.show ? (
        <div
          className={`bg-${alert.bgColor} mt-4 rounded text-white text-lg px-4 py-2 shadow-lg font-bold flex flex-col lg:flex-row justify-between items-center`}
        >
          <div className="flex flex-row items-center gap-2">
            {alert.bgColor.includes("red") ? (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm-8 56a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0zm8 104a12 12 0 1 1 12-12a12 12 0 0 1-12 12z"
                  fill="currentColor"
                ></path>
              </svg>
            ) : (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24zm49.531 85.79l-58.672 56a8.024 8.024 0 0 1-11.062 0l-29.328-28a8.007 8.007 0 0 1 11.062-11.58l23.797 22.728l53.14-50.727a8.007 8.007 0 0 1 11.063 11.578z"
                  fill="currentColor"
                ></path>
              </svg>
            )}

            {alert.message}
          </div>
          <button
            className="bg-white px-2 py-0.5 font-bold rounded text-gray-600 hover:bg-gray-300 transition duration-300"
            onClick={() => {
              setAlert({ ...alert, show: false });
            }}
          >
            X
          </button>
        </div>
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-4">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Editar Banner
        </h1>
        <p className="mb-12 text-gray-600">
          Acá podés modificar un banner, un banner puede ser comun o de empresas
          y se puede ocultar del home.
        </p>

        <div className="border border-gray-800 px-2 py-2 m-4 rounded bg-gray-200">
          <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 mt-2 mb-2 p-2 ">
            <input
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder="Título del Banner"
              name="titulo"
              value={bannerDatos.titulo}
              onChange={(e) => updateValue(e)}
            ></input>
            <div className="flex flex-col lg:grid lg:grid-cols-6 gap-2  ">
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  ACTIVO
                </label>
                <Switch
                  name="estado"
                  onChange={onSwitchChangeEstado}
                  checked={estado}
                />
              </div>
              <div className="flex flex-col ">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  FONDO IMAGEN
                </label>
                <Switch
                  name="fondo_imagen"
                  onChange={onSwitchChangeFondoImagen}
                  checked={fondo_imagen}
                />
              </div>
              {fondo_imagen === false ? (
                <>
                  <div className="flex flex-col ">
                    <label className="text-gray-500 text-xs font-medium mb-1">
                      COLOR FONDO
                    </label>
                    <input
                      type="color"
                      name="color"
                      value={bannerDatos.color}
                      onChange={(e) => updateValue(e)}
                    ></input>
                  </div>
                </>
              ) : null}
              <div className="flex flex-col ">
                <>
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    COLOR TEXTO
                  </label>
                  <input
                    type="color"
                    name="color_texto"
                    value={bannerDatos.color_texto}
                    onChange={(e) => updateValue(e)}
                  ></input>
                </>
              </div>
              <div className="flex flex-col ">
                <>
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    COLOR LINK
                  </label>
                  <input
                    type="color"
                    name="color_link"
                    value={bannerDatos.color_link}
                    onChange={(e) => updateValue(e)}
                  ></input>
                </>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  SECCION
                </label>
                <select
                  name="seccion"
                  value={bannerDatos.seccion}
                  onChange={(e) => updateSelect(e.target.value, "seccion")}
                  className=" rounded px-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-sm text-medium"
                >
                  <option value="HOME">HOME</option>
                  <option value="CURSOS">CURSOS</option>
                  <option value="PROGRAMAS">PROGRAMAS</option>
                  <option value="MEMBRESIAS">MEMBRESIAS</option>
                  <option value="EMPRESAS">EMPRESAS</option>
                </select>
              </div>
            </div>
            {fondo_imagen === false ? (
              <>
                <input
                  className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  placeholder="Subtítulo del Botón del Banner"
                  name="subtitulo"
                  value={bannerDatos.subtitulo}
                  onChange={(e) => updateValue(e)}
                ></input>
                <input
                  className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  placeholder="Texto Boton del Banner"
                  name="texto_boton"
                  value={bannerDatos.texto_boton}
                  onChange={(e) => updateValue(e)}
                ></input>
              </>
            ) : null}
          </div>
          <div className="flex flex-col lg:grid gap-4 mt-2 mb-2 p-2 ">
            {fondo_imagen === false ? (
              <>
                <input
                  className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  placeholder="Comentario descripcion del Banner"
                  name="comentario"
                  value={bannerDatos.comentario}
                  onChange={(e) => updateValue(e)}
                ></input>
                <input
                  className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  placeholder="Link de imagen del Banner"
                  name="imagen"
                  value={bannerDatos.imagen}
                  onChange={(e) => updateValue(e)}
                ></input>
              </>
            ) : null}
            {fondo_imagen === true ? (
              <input
                className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder="Link de fondo para imagen del Banner en desktop"
                name="fondo_imagen_desktop"
                value={bannerDatos.fondo_imagen_desktop}
                onChange={(e) => updateValue(e)}
              ></input>
            ) : null}
            {fondo_imagen === true ? (
              <input
                className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder="Link de fondo para imagen del Banner en movil"
                name="fondo_imagen_movil"
                value={bannerDatos.fondo_imagen_movil}
                onChange={(e) => updateValue(e)}
              ></input>
            ) : null}
            <input
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              placeholder="Link de Acción del Banner"
              name="redirect_link"
              value={bannerDatos.redirect_link}
              onChange={(e) => updateValue(e)}
            ></input>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={() => {
              if (habilitarEdicion) {
                actualizarDatos();
              } else {
                setHabilitarEdicion(!habilitarEdicion);
              }
            }}
            className={
              habilitarEdicion
                ? actualizando
                  ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
                  : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
                : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
            }
            disabled={actualizando}
          >
            {habilitarEdicion ? (
              actualizando ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            )}
            <p className="mb-0.5">
              {" "}
              {habilitarEdicion
                ? actualizando
                  ? "Actualizando"
                  : "Guardar Cambios"
                : "Editar"}
            </p>
          </button>
        </div>
      </div>
    </>
  );
}

export default EditBanner;
